.gkt-footer-wrapper {
  height: 40vh;
}
.gkt-background-img {
  height: 100%;
  position: relative;
}
.gkt-background-img .foote1 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 35vw;
  opacity: 0.5;
}
.gkt-background-img .foote2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20vw;
  opacity: 0.5;
}
.gkt-background-img .foote3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.gkt-bottom-detailes,
.gkt-top-detailes {
  display: flex;
  align-items: flex-end;
}
.gkt-top-detailes .gkt-left-side .gkt-logo {
  height: 5rem;
  padding: 10px 0;
  width: auto;
}
.gkt-top-detailes .gkt-left-side {
  width: 60%;
  padding: 1rem 0 1rem 3rem;
}
.gkt-footer-detaile-box {
  position: absolute;
  bottom: 0;

  z-index: 1;
}
.gkt-top-detailes .gkt-right-seid {
  width: 40%;
  padding: 1rem 3rem 1rem 0;
}
.gkt-top-detailes .gkt-right-seid p {
  margin: 0;
  text-align: right;
  line-height: 1.4;
  color: #576f4f;
  text-shadow: 0px 0px 2px #576f4f;
  font-size: 17px;
}
.gkt-bottom-detailes .gkt-left-side {
  width: 60%;
  padding: 0 0 0 3rem;
}
.gkt-bottom-detailes .gkt-left-side p {
  font-size: 15px;
  color: #576f4f;
  text-shadow: 0px 0px 2px #576f4f;
}
.gkt-bottom-detailes .gkt-right-seid {
  width: 40%;
  text-align: right;
  color: white;
  display: flex;
  justify-content: flex-end;
  padding: 0 3rem 0 0;
}
.gkt-bottom-detailes .gkt-right-seid a:first-child {
  border-right: #576f4f 1px solid;
}
.gkt-bottom-detailes .gkt-right-seid a {
  color: white;
  width: 40px;
  margin: 0%;
  padding: 5px 5px;
  background-color: #ce3034;
  font-size: 25px;
  transition: all 0.35s ease;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.gkt-bottom-detailes .gkt-right-seid a i {
  margin: 5px auto;
}
.gkt-bottom-detailes .gkt-right-seid a:hover {
  background-color: #a4e378;
}

@media only screen and (max-width: 769px) {
  .gkt-background-img {
    padding: 0 2rem;
  }
  .gkt-footer-detaile-box {
    left: 0;
  }

  .gkt-top-detailes {
    display: block;
  }
  .gkt-top-detailes .gkt-left-side {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .gkt-top-detailes .gkt-left-side img {
    margin: 0 auto;
  }
  .gkt-top-detailes .gkt-right-seid {
    width: 100%;
  }
  .gkt-top-detailes .gkt-right-seid p {
    text-align: center;
    padding: 0 2rem;
    font-size: 16px;
  }
  .gkt-bottom-detailes {
    padding: 0 1rem 1rem;
  }
  .gkt-bottom-detailes .gkt-left-side p {
    margin: 0%;
    font-size: 13px;
  }
  .gkt-footer-wrapper {
    height: 43vh;
  }
  .gkt-top-detailes .gkt-left-side {
    padding-left: 0%;
  }
  .gkt-bottom-detailes .gkt-left-side {
    padding-left: 0%;
  }
  .gkt-top-detailes .gkt-right-seid {
    padding-right: 0%;
  }
  .gkt-bottom-detailes .gkt-right-seid {
    padding-right: 0%;
  }
}
