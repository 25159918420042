.gkt-aboutus-wrapper {
  height: 89vh;
  position: relative;
  overflow: hidden;
}
.gkt-aboutus-container {
  height: 100%;
}
.gkt-aboutebackimg-container {
  background-image: url("../images/about2.webp");
  background-size: cover;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.gkt-aboutus-text-continer {
  position: relative;
  height: 100%;
  margin: 0 50% 0 15%;
  z-index: 5;
  display: flex;
  align-items: center;
}
.gkt-aboutus-text {
  color: white;
}
.gkt-aboutus-h1text {
  font-size: 4rem;
  margin: 0;
  text-shadow: -1px 1px 2px #000;
  line-height: 1;
  font-weight: 700 !important;
}
.gkt-aboutus-text p {
  margin-bottom: 80px;
  text-shadow: -1px 1px 2px #000;
}
.gkt-aboutus-text a {
  text-shadow: -1px 1px 2px #000;
}
.gkt-aboutus-text h4 {
  margin-top: 2rem;
  width: auto;
  background-color: rgba(255, 255, 255, 1);
  color: #4a9f0f;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  text-transform: uppercase;
}

.gkt-aboutus-cloud-overlay .cloudegrpbottom {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -30%;
  right: 0;
  z-index: 1;
}
.gkt-aboutus-cloud-overlay .cloudegrpbottom img {
  width: 100%;
}
.gkt-aboutus-cloud-overlay .cloudegrpbottom1 img {
  width: 100%;
}
.gkt-aboutus-cloud-overlay .cloudegrpbottom1 {
  width: 100%;
  height: auto;
  position: absolute;
  top: -28%;
  transform: rotate(180deg);
  right: 0;
  z-index: 1;
}
.gkt-aboutus-cloud-overlay .solocloud {
  width: 50%;
  position: absolute;
  top: 9%;
  right: 31%;
  height: auto;
  z-index: 2;
}
.gkt-aboutus-cloud-overlay .solocloud1 {
  width: 50%;
  position: absolute;
  top: 45%;
  left: -10%;
  height: auto;
  opacity: 0.7;
  transform: rotate(25deg);
  z-index: 2;
}
.gkt-contact-cloud-overlay .solocloud1 img {
  width: 100%;
}
.gkt-contact-cloud-overlay .solocloud img {
  width: 100%;
}
.gkt-aboutus-topwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}
.gkt-aboutus-bottomwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}
.gkt-aboutustealeaf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.gkt-aboutusleaf {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.gkt-aboutusleaf .aboutusleafblur6 {
  top: 10%;
  left: 5%;
  transform: rotate(235deg);
  width: 5%;
  height: auto;
}
.gkt-aboutusleaf .aboutusleafgrp3 {
  top: -10%;
  left: -2%;
  transform: rotate(332deg);
  width: 45%;
  height: auto;
}
.gkt-aboutusleaf .aboutusleafgrp2 {
  bottom: 10%;
  left: 3%;
  transform: rotate(365deg);
  width: 15%;
  height: auto;
}
.gkt-aboutusleaf .aboutusleafblur1 {
  top: 10%;
  left: 31%;
  transform: rotate(182deg);
  width: 3%;
  height: auto;
}

.gkt-aboutus-detil-wrapper {
  height: auto;
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
}
.gkt-aboutus-detil-box {
  height: 100%;
}
.gkt-aboutus-detilgrid-box {
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  position: relative;
  height: 100%;
  align-items: center;
}
.gkt-main-aboutus-detil-sec {
  position: relative;
  grid-column: 2/3;
}
.gkt-main-aboutus-detil-heding {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  width: 100%;
  transition: 0.5s all ease;
}
.gkt-main-aboutus-detil-heding h1 {
  font-family: "Times New Roman", Times, serif;
  color: #576f4f;
  position: relative;
  z-index: 2;
}
.gkt-main-aboutus-detil-img {
  background-image: url("../images/aboutus.webp");
  background-size: cover;
  background-position: 86% center;
  background-repeat: no-repeat;
  width: 35%;
  padding-top: 2rem;
  color: #576f4f;
  outline: 2px solid #4a9f0f;
  outline-offset: 10px;
  transition: 0.5s all ease;
}
.gkt-main-aboutus-detil-detailes {
  width: 65%;
  padding-top: 1rem;
  color: #576f4f;
  text-align: left;
}
.gkt-main-aboutus-detil-detailes p {
  font-size: 18px;
  padding-left: 3rem;
  transition: 0.5s all ease;
}
.gkt-aboutus-detales-box {
  display: flex;
  transition: 0.5s all ease;
}
.gkt-aboutus-mision {
  background-image: url("../images/about3.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.gkt-aboutus-detales-box .gkt-aboutus-detales-box-inner {
  width: 50%;
  padding: 2rem;
  background-color: hsla(95, 83%, 34%, 0.4);
  outline: 2px solid#a4e378;
  outline-offset: -15px;
  border-radius: 5px;
  transition: 0.5s all ease;
}
.gkt-aboutus-detales-box .gkt-aboutus-detales-box-inner h1 {
  font-family: "Times New Roman", Times, serif;
  color: #fff;
  text-shadow: -1px 1px 2px #576f4f;
}
.gkt-aboutus-detales-box .gkt-aboutus-detales-box-inner p {
  color: #fff;
  padding-left: 1rem;
  text-shadow: -1px 1px 2px #576f4f;
}
.aboutuslead img {
  width: 100%;
}
@media only screen and (max-width: 869px) {
  .gkt-aboutus-detales-box {
    flex-direction: column;
  }
  .gkt-main-aboutus-detil-img {
    width: 100%;
    height: 500px;
  }
  .gkt-main-aboutus-detil-detailes {
    width: 100%;
    text-align: center;
    padding-top: 3rem;
  }
  .gkt-main-aboutus-detil-detailes p {
    padding: 0;
  }
  .gkt-aboutus-detales-box .gkt-aboutus-detales-box-inner {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .gkt-aboutus-text-continer {
    margin: 0 10%;
    text-align: center;
    align-items: unset;
    padding: 30% 0 0 0;
  }
  .gkt-aboutus-text {
    margin-top: auto;
    margin-bottom: 10vh;
  }
  .gkt-main-aboutus-detil-detailes p {
    font-size: 16px;
  }
  .gkt-aboutus-h1text {
    font-size: 3rem;
  }
  .gkt-aboutebackimg-container {
    background-position: 80% center;
  }
  .gkt-aboutus-detilgrid-box {
    grid-template-columns: 1rem 1fr 1rem;
  }
  .gkt-aboutus-detales-box .gkt-aboutus-detales-box-inner h1 {
    text-align: center;
  }
  .gkt-aboutus-detales-box .gkt-aboutus-detales-box-inner p {
    padding: 0%;
    text-align: center;
  }
  .gkt-aboutusleaf .aboutusleafblur6 {
    width: 15%;
  }
  .gkt-aboutusleaf .aboutusleafgrp3 {
    width: 133%;
  }
  .gkt-aboutusleaf .aboutusleafblur1 {
    top: 6%;
    width: 5%;
  }
  .gkt-aboutusleaf .aboutusleafgrp2 {
    width: 44%;
    bottom: 5%;
  }
  .gkt-aboutus-cloud-overlay .solocloud {
    top: -9%;
    width: 100%;
    right: -17%;
  }
}
@media screen and (min-height: 900px) and (orientation: portrait) {
  .gkt-about-hedding-box {
    height: 200px;
  }
  .gkt-about-hedding-box .gkt-about-round {
    height: 200px;
    width: 200px;
  }
  .gkt-about-hedding-box h1 {
    font-size: 95px;
  }
  .gkt-about-hedding-box .tea-leaf-forabout img {
    bottom: -13%;
  }
}
