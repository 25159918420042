.gkt-product-wrapper {
  height: 100vh;
  overflow: hidden;
  padding: 3rem 0;
  position: relative;
}
.productteawrapper {
  height: 720px;
}
.productlistofsmallbox .gkt-product-wrapper {
  height: 80vh;
}
.gkt-product-box {
  height: 100%;
}
.gkt-productgrid-box {
  display: grid;
  grid-template-columns: 3rem 1fr 10fr 1fr 3rem;
  position: relative;
  height: 100%;
}
.gkt-main-product-sec {
  position: relative;
}
.gkt-main-product_tea {
  grid-column: 2/5;
}
.productteawrapper .gkt-main-product_tea {
  height: 600px;
}
.gkt-productgrid-box .gkt-side-navigation {
  grid-column: 2/3;
  margin-top: 3vh;
  z-index: 3;
  transition: all 0.5s ease;
}
.gkt-productgrid-box .gkt-side-navigation p {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
  color: #576f4f;
}
.side-link {
  text-decoration: none;
  color: #576f4f;
}
.side-link:hover {
  color: #576f4f;
}
.gkt-productgrid-box .gkt-side-navigation .side-link {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
}

.gkt-productgrid-box .gkt-varticle-line {
  margin-left: 3px;
  margin-bottom: 0.6rem;
  border-left: 1px solid #4a9f0f;
  height: 60px;
}
.gkt-main-product-heding {
  display: flex;
  justify-content: center;
  margin-top: 8vh;
  position: relative;
}
.gkt-main-product-heding h1 {
  font-family: "Times New Roman", Times, serif;
  color: #576f4f;
  position: absolute;
  margin-top: 0vh;
  transition: all 0.5s ease;
}
.productteawrapper .gkt-main-product-heding h1 {
  margin-top: 0vh;
}
.productlistofsmallbox .productteawrapper .gkt-main-product-heding h1 {
  margin-top: 20vh;
}
.gkt-main-product-slider {
  width: 76vw;
  margin: 0 auto;
}
.swiper {
  position: unset;
}
.swiper-button-next,
.swiper-button-prev {
  top: 90%;
  border: 2px solid #576f4f;
  padding: 0 20px;
  margin: 0;
  color: #576f4f;
  transition: 0.5s ease;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #a4e373;
}
.gkt-main-product_tea .gkt-main-product-slider .swiper-button-next {
  right: 0rem;
}
.gkt-main-product_tea .gkt-main-product-slider .swiper-button-prev {
  left: 0rem;
}
.productlistofsmallbox .gkt-main-product_tea {
  height: 75vh;
}
.gkt-main-product-slider .swiper-button-next {
  right: -7rem;
}
.gkt-main-product-slider .swiper-button-prev {
  left: -7rem;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 18px !important;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
  font-weight: bold !important;
}
.swiper-button-next:after {
  content: "\2192";
}
.swiper-button-prev:after {
  content: "\2190";
}
.gkt-carosel-imageboc img {
  width: 100%;
}
.gkt-carosel-imageboc {
  height: 55vh;
  display: flex;
  align-items: flex-end;
}
.productpagelist .gkt-carosel-imageboc {
  height: 25rem;
}

.gkt-carosel-detailesbox {
  text-align: center;
  padding: 0 2.5rem;
}
.gkt-carosel-detailesbox h4 {
  height: 5vh;
  margin-bottom: 2rem;
  font-weight: bolder;
  font-size: 18px;
  color: #576f4f;
}
.gkt-carosel-detailesbox p {
  height: 3vh;
  font-style: italic;
  color: #576f4f;
}
.gkt-main-product-sec .gkt-big-hedding {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gkt-main-product-sec .gkt-big-hedding h1 {
  height: 250px;
  font-size: 15vw;
  color: #a4e373;
  margin-bottom: 55vh;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.gkt-protealeaf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.gkt-proleaf {
  height: 100%;
  position: relative;
}
.gkt-proleaf .proleafblur6 {
  top: 5%;
  right: -5%;
  transform: rotate(10deg);
  width: 13%;
}
.gkt-proleaf .proleafblur1 {
  top: 32%;
  right: 5%;
  transform: rotate(-125deg);
  width: 3%;
}
.gkt-proleaf .proleafgrp1 {
  top: 0%;
  right: 5%;
  width: 5%;
}
.gkt-proleaf .proleafgrp4 {
  top: 8%;
  right: 25%;
  width: 6%;
}
.gkt-proleaf .proleafgrp2 {
  top: 15%;
  right: 10%;
  transform: rotate(100deg);
  width: 6%;
}
.gkt-proleaf .proleafgrp11 {
  top: 15%;
  right: 38%;
  transform: rotate(100deg);
  width: 5%;
}
.gkt-proleaf .proleafblur3 {
  top: 0%;
  right: 20%;
  transform: rotate(100deg);
  width: 4%;
}
.gkt-proleaf .proleafgrp31 {
  top: -5%;
  right: 11%;
  transform: rotate(80deg);
  width: 20%;
}
.gkt-proleaf .proleafgrp32 {
  top: 7%;
  right: 13%;
  transform: rotate(0deg);
  width: 5%;
}
.gkt-proleaf .proleafblur61 {
  top: 4%;
  right: 12%;
  transform: rotate(234deg);
  width: 4%;
}
.gkt-proleaf .proleafgrp41 {
  bottom: 4%;
  left: 12%;
  transform: rotate(234deg);
  width: 4%;
}
.gkt-proleaf .proleafblur5 {
  bottom: -3%;
  left: -1%;
  transform: rotate(189deg);
  width: 8%;
}
.gkt-proleaf .proleafgrp12 {
  bottom: 2%;
  left: 9%;
  transform: rotate(189deg);
  width: 3%;
}
.slide {
  margin-top: 30vh;
}
.productpagelist {
  transition: 0.5s ease all;
  height: 100%;
}
.productpagelist .slide {
  margin-top: -1rem;
}
.productlistofsmallbox .productpagelist .slide {
  margin-top: 35vh;
}
.swiper-wrapper {
  height: 83vh;
}
.swiper-button-next,
.swiper-button-prev {
  top: 88%;
}
@media only screen and (max-width: 1111px) {
  .gkt-carosel-imageboc {
    height: 42vh;
  }
  .gkt-productgrid-box {
    grid-template-columns: 2rem 1fr 10fr 1fr 2rem;
  }
}
@media only screen and (max-width: 769px) {
  .gkt-product-wrapper {
    height: 90vh;
  }
  .swiper-button-prev {
    left: -3rem;
  }
  .swiper-button-next {
    right: -3rem;
  }
  .gkt-carosel-detailesbox {
    padding: 0 1rem;
  }
  .gkt-carosel-detailesbox h4 {
    margin-bottom: 1rem;
  }
  .gkt-carosel-imageboc {
    height: 35vh;
  }
  .gkt-proleaf .proleafblur5 {
    bottom: -2%;
    left: -3%;
    width: 15%;
  }
  .gkt-proleaf .proleafgrp41 {
    width: 10%;
  }
  .gkt-proleaf .proleafgrp12 {
    width: 6%;
  }
}
@media only screen and (max-width: 550px) {
  .gkt-product-wrapper {
    height: 90vh;
  }
  .gkt-productgrid-box {
    grid-template-columns: 2rem 1fr 10fr 1fr 2rem;
  }
  .swiper-button-next {
    right: 0rem;
  }
  .swiper-button-prev {
    left: 0rem;
  }
  .gkt-productgrid-box .gkt-side-navigation {
    display: none;
  }
  .gkt-main-product-sec {
    grid-column: 2/5;
  }
  .gkt-carosel-detailesbox h4 {
    height: 7vh;
    margin-bottom: 1rem;
    font-size: 23px;
  }
  .gkt-carosel-imageboc {
    height: 50vh;
  }
  .gkt-carosel-forproductpage {
    height: 42vh;
  }
  .gkt-main-product-slider {
    width: auto;
  }
  .gkt-main-product-heding {
    margin: 0%;
    padding: 1rem 0 0 0;
  }
  .gkt-main-product-heding h1 {
    font-size: 36px;
  }
  .gkt-big-hedding h1 {
    visibility: hidden;
    position: relative;
  }
  .gkt-main-product-sec .gkt-big-hedding h1 {
    width: 100%;
    text-align: center;
  }
  .gkt-big-hedding h1:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 55vw;
    /* text-align: center; */
    width: 100%;
    content: "tea";
  }
  .gkt-proleaf .proleafblur6 {
    right: -13%;
    width: 25%;
  }
  .gkt-proleaf .proleafgrp4 {
    top: 24%;
    right: 11%;
    width: 12%;
  }
  .gkt-proleaf .proleafgrp1 {
    top: 2%;
    right: 28%;
    width: 13%;
  }
  .gkt-proleaf .proleafgrp2 {
    top: 20%;
    right: 2%;
    width: 9%;
  }
  .gkt-proleaf .proleafgrp32 {
    width: 23%;
  }
  .gkt-proleaf .proleafblur3 {
    top: 1%;
    right: 3%;
  }
  .gkt-proleaf .proleafgrp11 {
    top: 18%;
    right: 30%;
  }
  .gkt-proleaf .proleafblur5 {
    bottom: -2%;
    left: -6%;
    width: 30%;
  }
  .gkt-proleaf .proleafgrp41 {
    width: 26%;
  }
  .gkt-proleaf .proleafgrp12 {
    width: 16%;
  }
  .gkt-main-product-slider .swiper-button-next {
    right: -1rem;
  }
  .gkt-main-product-slider .swiper-button-prev {
    left: -1rem;
  }
  .productpagelist .slide {
    margin-top: 0vh;
  }
  .productteawrapper .gkt-main-product-heding h1 {
    margin-top: 0vh;
  }
  .productlistofsmallbox .productteawrapper .gkt-main-product-heding h1 {
    margin-top: 40vh;
  }
  .productlistofsmallbox .productpagelist .slide {
    margin-top: 68vh;
  }
}
@media screen and (min-height: 900px) and (orientation: portrait) {
  .gkt-product-wrapper {
    height: 720px;
  }
  .swiper-wrapper {
    height: 624px;
  }
  .slide {
    height: 100%;
    margin-top: 220px;
  }
  .gkt-carosel-detailesbox h4 {
    height: 23px;
  }
  .gkt-main-product-sec {
    height: 624px;
  }
  .gkt-main-product-heding {
    margin: 0;
  }
  .gkt-main-product-slider {
    height: 100%;
  }
  .swiper {
    height: 100%;
  }
  .gkt-main-product-slider .swiper-button-prev {
    left: -3rem;
  }
  .gkt-main-product-slider .swiper-button-next {
    right: -3rem;
  }
  .gkt-main-product-sec .gkt-big-hedding h1 {
    margin-bottom: 430px;
  }
}
