.gkt-herosection-wrapper {
  height: 89vh;
  position: relative;
  overflow: hidden;
}
.gkt-hero-container {
  height: 100%;
  width: 100%;
}
.overlayofherosection {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
  pointer-events: none;
}
.backgroundwhitecolor {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  background-color: white;
}
.backgroundwhitecolortop {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  background-color: white;
}
.overlayofherosection .topcloud {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.overlayofherosection .bottomcloud {
  width: 100%;
  transform: rotate(180deg);
  position: absolute;
  top: 0;
}
.gkt-image-container {
  height: 100%;
}
.gkt-image-container .backgroundheroimage {
  width: 100%;
  position: absolute;
  top: -10%;
  z-index: 0;
}
.gkt-bottomwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );

  z-index: 2;
}
.gkt-hero-text-continer {
  position: relative;
  height: 100%;
  margin: 0 15%;
  z-index: 5;
  display: flex;
  align-items: center;
}
.gkt-hero-text {
  color: white;
}
.gkt-hero-h1text {
  font-size: 4rem;
  margin: 0;
  text-shadow: -1px 1px 2px #000;
  line-height: 1;
  font-weight: 700 !important;
}
.gkt-hero-ptext {
  margin-bottom: 80px;
  text-shadow: -1px 1px 2px #000;
}
.gkt-cloud-overlay {
  z-index: 2;
}
.gkt-cloud-overlay .cloudegrpbottom {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 1;
  bottom: -20%;
  right: 0;
}
.gkt-cloud-overlay .cloudegrpbottom img {
  width: 100%;
}
.gkt-cloud-overlay .solocloud {
  width: 50%;
  position: absolute;
  top: 25%;
  right: -10%;
  z-index: 1;
  height: auto;
}
.gkt-cloud-overlay .solocloud img {
  width: 100%;
}
.gkt-cloud-overlay .solocloud1 img {
  width: 100%;
}
.gkt-cloud-overlay .solocloud1 {
  width: 50%;
  position: absolute;
  top: 20%;
  left: -10%;
  z-index: 1;
  height: auto;
  opacity: 0.7;
}
.gkt-tealeaf-container {
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
}
.gkt-leaf {
  height: 100%;
  position: relative;
}
.gkt-leaf .leafblur6 {
  top: 7%;
  left: 30%;
  transform: rotate(20deg);
  width: 70px;
  height: auto;
}
.gkt-leaf .leafblur1 {
  top: 0%;
  right: 26%;
  transform: rotate(45deg);
  width: 75px;
  height: auto;
}
.gkt-leaf .leafblur3 {
  top: 22%;
  left: 47%;
  transform: rotate(-65deg);
  width: 40px;
  height: auto;
}
.gkt-leaf .leafblur4 {
  top: 10%;
  right: 25%;
  width: 40px;
  height: auto;
}
.gkt-leaf .leafgrp3 {
  top: 0%;
  left: 10%;
  width: 250px;
  height: auto;
}
.gkt-leaf .leafgrp31 {
  top: 20%;
  right: 35%;
  width: 140px;
  transform: rotate(100deg);
  height: auto;
}
.gkt-leaf .leafgrp1 {
  bottom: 0%;
  right: 10%;
  width: 200px;
  transform: rotate(-30deg);
  height: auto;
}
.gkt-leaf .leafgrp2 {
  bottom: 2%;
  left: 5%;
  width: 100px;
  transform: rotate(75deg);
  height: auto;
}
.gkt-leaf .leafblur31 {
  bottom: 5%;
  left: 3%;
  width: 8px;
  transform: rotate(75deg);
  height: auto;
}

.gkt-leaf .leafblur2 {
  bottom: 6%;
  left: 15%;
  width: 20px;
  height: auto;
}
.aboutuslead img {
  width: 100%;
}
@media only screen and (max-width: 1050px) {
  .gkt-image-container .backgroundheroimage {
    top: 0%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .gkt-cloud-overlay .cloudegrpbottom {
    bottom: 0%;
  }
}
@media only screen and (max-width: 769px) {
  .gkt-hero-h1text {
    font-size: 3rem;
    text-align: center;
  }
  .gkt-hero-ptext {
    text-align: center;
  }
  .gkt-cloud-overlay .solocloud {
    width: 100%;
    top: 23%;
    right: -43%;
  }

  .gkt-cloud-overlay .cloudegrpbottom {
    bottom: 0%;
    width: 250%;
  }
  .gkt-cloud-overlay .solocloud1 {
    width: 100%;
    top: 47%;
    left: -16%;
  }
  .gkt-leaf .leafblur6 {
    left: 6%;
  }
  .gkt-leaf .leafblur3 {
    left: 79%;
  }
  .gkt-leaf .leafblur1 {
    right: 10%;
    width: 20px;
  }
  .gkt-leaf .leafgrp1 {
    width: 185px;
  }
  .gkt-hero-text {
    width: 100%;
  }
  .gkt-leaf .leafblur2 {
    bottom: 2%;
    left: 38%;
  }
  .gkt-image-container .backgroundheroimage {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
@media only screen and (max-width: 550px) {
  .overlayofherosection .topcloud {
    width: 450%;
  }
  .overlayofherosection .bottomcloud {
    width: 450%;
  }
}
