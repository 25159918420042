.gkt-tea-wrapper {
  height: 640px;
  overflow: hidden;
  position: relative;
}
.gkt-tea-container {
  height: 100%;
  width: 100%;
}
.overlayofproductsection {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 7;
  pointer-events: none;
}
.gkt-teaimage-container {
  background-image: url("../images/teahero.webp");
  background-size: cover;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.gkt-teacloud-overlay {
  z-index: 1;
}
.gkt-teacloud-overlay .cloudegrpbottom {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -20%;
  right: 0;
}
.gkt-teacloud-overlay .cloudegrpbottom img {
  width: 100%;
}

.gkt-teatopwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 0;
}
.gkt-teabottomwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 0;
}
.gkt-teacloud-overlay .solocloud {
  width: 40%;
  position: absolute;
  top: 4%;
  right: 11%;
  height: auto;
  opacity: 0.8;
}
.gkt-teacloud-overlay .solocloud img {
  width: 100%;
}
.gkt-teacloud-overlay .solocloud1 img {
  width: 100%;
}
.gkt-teacloud-overlay .solocloud1 {
  width: 60%;
  position: absolute;
  top: 2%;
  left: -20%;
  height: auto;
  opacity: 0.7;
  transform: scaleX(-1);
}
.gkt-product-item-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  position: relative;
  z-index: 5;
}
.gkt-teagrid-inner {
  grid-column: 2/3;
  width: 100%;
}
.gkt-teagrid-inner {
  position: relative;
}
.gkt-product-box-set {
  position: absolute;
  bottom: 5%;
  right: 50%;
  transform: translateX(40%);
  width: 500px;
}
.gkt-small-teabox {
  position: absolute;
  bottom: 1%;
  right: 50%;
  transform: translateX(42%);
  width: 560px;
}
.gkt-product-2box-set {
  position: absolute;
  bottom: 5%;
  right: 50%;
  transform: translateX(55%);
  width: 480px;
}
.gkt-tea-cup-set {
  position: absolute;
  bottom: 5%;
  right: 50%;
  transform: translateX(50%);
  width: 400px;
}
.redtea {
  width: 100%;
}
.teacupred {
  width: 100%;
}
.teapouch {
  width: 100%;
}
.gkt-teatealeaf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.gkt-bogteatealeaf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.gkt-bogteatealeaf-container .gkt-tealeaf {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gkt-teatealeaf-container .gkt-tealeaf {
  height: 100%;
  position: relative;
}
.gkt-tealeaf .tealeafblur1 {
  top: 7%;
  left: 11%;
  transform: rotate(45deg);
  width: 5%;
  height: auto;
}
.gkt-tealeaf .tealeafblur3 {
  top: 7%;
  right: 6%;
  transform: rotate(105deg);
  width: 10%;
  height: auto;
}
.gkt-tealeaf .tealeafblur5 {
  top: 55%;
  left: -2%;
  transform: rotate(206deg);
  width: 9%;
  height: auto;
}
.gkt-tealeaf .tealeafblur4 {
  bottom: 10%;
  left: 6%;
  transform: rotate(386deg);
  width: 5%;
  height: auto;
}
.gkt-tealeaf .tealeafgrp4 {
  bottom: 23%;
  right: 5%;
  transform: rotate(422deg);
  width: 16%;
  height: auto;
}
.gkt-tealeaf .tealeafblur11 {
  bottom: 23%;
  right: 14%;
  transform: rotate(288deg);
  width: 3%;
  height: auto;
}
.gkt-tealeaf .tealeafgrp1 {
  bottom: 4%;
  right: 18%;
  transform: rotate(125deg);
  width: 14%;
  height: auto;
}
.gkt-tealeaf .tealeafgrp2 {
  bottom: 6%;
  right: 4%;
  transform: rotate(286deg);
  width: 11%;
  height: auto;
}
.gkt-tealeaf .tealeafgrp3 {
  bottom: -11%;
  left: 4%;
  transform: rotate(176deg);
  width: 31%;
  height: auto;
}
.gkt-tealeaf .tealeafgrp41 {
  bottom: 5%;
  left: 26%;
  transform: rotate(197deg);
  width: 5%;
  height: auto;
}
.gkt-tealeaf .tealeafblur31 {
  bottom: 3%;
  left: 53%;
  transform: rotate(119deg);
  width: 3%;
  height: auto;
}
.gkt-2tea-wrapper {
  height: 720px;
  overflow: hidden;
  position: relative;
}
.gkt-3tea-wrapper {
  height: 720px;
  overflow: hidden;
  position: relative;
}
.gkt-2tea-container {
  display: flex;
  height: 100%;
}
.gkt-3tea-container {
  display: grid;
  grid-template-columns: 3rem 1fr 1fr 3rem;
  height: 100%;
  align-items: center;
}
.gkt-3tea-container .gkt-box-area {
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  transition: 0.5s ease all;
}
.gkt-3tea-wrapper .gkt-tea-container {
  padding: 5rem 0;
}
.gkt-2tea-container .gkt-box-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.gkt-2tea-image {
  height: 80%;
  transition: 0.5s ease all;
}
.gkt-3tea-image {
  width: 80%;
  /* margin: 0 10%; */
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.gkt-detailes-area h2 {
  font-family: "Times New Roman", Times, serif;
  color: #576f4f;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  margin: 0%;
  font-weight: bolder;
  font-size: 2.5vw;
  line-height: 1;
  padding-left: 2rem;
  transition: 0.5s ease all;
}
.gkt-detailes-area .detaile-area-p {
  color: #576f4f;
  padding-left: 2rem;
  transition: 0.5s ease all;
}
.gkt-tea2horizontal-line {
  border-top: 3px solid #a4e378;
  border-radius: 10px;
  width: 150px;
  text-shadow: -1px 1px 2px #000;
  margin-left: 2rem;
  transition: 0.5s ease all;
}
.gkt-detailes-area-inner {
  display: block;
}
.backtextcontainer {
  position: absolute;
  top: 50%;
  left: -2%;
  transform: translateY(-50%);
}
.gkt-backtext {
  width: 100%;
  text-align: center;
  font-size: 30vw;
  line-height: 1;
  font-family: "Times New Roman", Times, serif;
  color: #4a9f0f;
  z-index: 0;
  text-transform: uppercase;
  margin: 0%;
  font-weight: bolder;
  opacity: 0.5;
  position: relative;
  left: -45%;
}
.paddingno {
  padding-bottom: 0% !important;
}
.gkt-tea2horizontal-line-inner {
  display: flex;
  align-items: center;
  transition: 0.5s ease all;
}
.gkt-tea2horizontal-line-inner-img {
  width: 20%;
  padding: 2rem;
}
.gkt-tea2horizontal-line-inner-img img {
  width: 100%;
  height: auto;
}
.gkt-tea2horizontal-line-inner-detailes {
  width: 80%;
  padding: 0 10rem 0 0;
}
.gkt-tea2horizontal-line-inner-detailes h4 {
  text-transform: uppercase;
  color: #4a9f0f;
  font-size: 22px;
}
.gkt-tea2horizontal-line-inner-detailes p {
  color: #576f4f;
  font-size: 14px;
  margin: 0%;
}
.gkt-bogteatealeaf-container .gkt-tealeaf .teapouch {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin-top: 0%;
}
.aboutuslead img {
  width: 100%;
}
.buynowbtn {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 6;
  background-color: #a4e378;
  border: 2px solid #4a9f0f;
  border-radius: 30px;
  padding: 5px 25px;
  color: #4a9f0f;
  transition: all 0.5s ease;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.buynowbtn:hover {
  background-color: #fff;
  border: 2px solid #a4e378;
  color: #4a9f0f;
}
@media only screen and (max-width: 1030px) {
  .gkt-tea2horizontal-line-inner-detailes {
    padding: 0 1rem 0 0;
  }
  .gkt-tea2horizontal-line-inner-img {
    padding: 1rem;
  }
}
@media only screen and (max-width: 550px) {
  .gkt-product-box-set {
    transform: translateX(45%);
    width: 382px;
  }
  .gkt-small-teabox {
    transform: translateX(48%);
    width: 468px;
    bottom: 0%;
  }
  .gkt-tea-cup-set {
    transform: translateX(54%);
    width: 289px;
  }
  .gkt-teacloud-overlay .cloudegrpbottom {
    bottom: 13%;
  }
  .gkt-teacloud-overlay .solocloud {
    width: 139%;
    top: 8%;
    right: -78%;
  }
  .gkt-teacloud-overlay .solocloud1 {
    width: 111%;
    top: 34%;
    left: -48%;
  }
  .gkt-tealeaf .tealeafblur5 {
    left: -5%;
    width: 20%;
  }
  .gkt-3tea-wrapper {
    height: auto;
  }
  .gkt-3tea-image {
    width: 100%;
  }
  .gkt-3tea-container {
    display: flex;
    flex-direction: column;
  }
  .gkt-tea2horizontal-line-inner {
    flex-direction: column;
  }
  .gkt-tea2horizontal-line-inner-detailes {
    padding: 10px 0;
    text-align: center;
  }
  .gkt-tea2horizontal-line-inner-img {
    padding: 0%;
  }
  .gkt-tea2horizontal-line {
    margin: 0 auto;
  }
  .gkt-detailes-area .detaile-area-p {
    padding: 0;
    text-align: center;
  }
  .gkt-detailes-area h2 {
    padding: 0%;
    text-align: center;
    font-size: 10vw;
  }
  .gkt-detailes-area {
    padding-top: 1rem;
  }
  .gkt-3tea-wrapper .gkt-tea-container {
    padding: 4rem 0;
  }
  .gkt-2tea-image {
    height: 100vw;
    margin-left: 26px;
  }
  .gkt-backtext {
    font-size: 119vw;
  }
  .gkt-bogteatealeaf-container .gkt-tealeaf img {
    height: 100%;
    margin-top: 30px;
  }
  .gkt-bogteatealeaf-container .gkt-tealeaf .teapouch {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    margin-top: 0%;
  }
}
