.gkt-404-wrapper {
  height: 89vh;
  overflow: hidden;
  position: relative;
}
.gkt-dilaies-404 {
  height: 100%;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gkt-image-box {
  height: 50vh;
  display: flex;
  justify-content: center;
}
.gkt-image-box img {
  height: 100%;
  width: auto;
}
.gkt-text-box {
  text-align: center;
  padding: 3rem 0;
}
.gkt-text-box h1 {
  color: #4a9f0f;
  text-transform: uppercase;
}
@media only screen and (max-width: 550px) {
  .gkt-image-box img {
    width: 100%;
    height: auto;
  }
  .gkt-image-box {
    width: 100%;
  }
  .gkt-404-wrapper {
    padding: 0 3rem;
  }
  .gkt-dilaies-404 {
    padding: 0%;
  }
}
