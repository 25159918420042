.gkt-teainfo-wrapper {
  width: 100%;
  height: 550px;
  overflow: hidden;
}
.gkt-backgroundimage-container {
  height: 100%;
  position: relative;
}
.backgroundimage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  height: auto;
  width: 100%;
}
.gkt-teainfo-gridsec {
  position: relative;
  padding: 0 3rem;
}
.gkt-info-container {
  width: 500px;
  position: absolute;
  top: 31%;
  left: -15%;
  color: white;
  text-shadow: -1px 1px 2px #000;
  z-index: 5;
  padding-left: 3rem;
}
.gkt-teacup-img {
  position: relative;
}
.gkt-teacup-img .teacup-img {
  position: relative;
  top: 0%;
  right: -55%;
  margin-top: 30px;
  height: 500px;
  z-index: 3;
}
.gkt-bottomwhite-teainfo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 2;
}
.gkt-backgroundimage-container .gkt-topwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 2;
}
.gkt-cloud-teainfo-overlay {
  z-index: 1;
}
.gkt-cloud-teainfo-overlay .teainfo-cloudegrpbottom {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -20%;
  right: 0;
  z-index: 1;
}
.gkt-cloud-teainfo-overlay .teainfo-solocloud {
  width: 50%;
  position: absolute;
  top: 5%;
  right: 10%;
  height: auto;
  z-index: 1;
}
.gkt-infotealeaf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 4;
}
.gkt-infoleaf {
  height: 100%;
  position: relative;
}
.position-absolute {
  position: absolute;
}
.gkt-infoleaf .infoleafblur6 {
  top: 0%;
  left: 5%;
  transform: rotate(10deg);
  width: 2%;
}
.gkt-infoleaf .infoleafblur1 {
  top: 20%;
  left: 40%;
  transform: rotate(-125deg);
  width: 3%;
}
.gkt-infoleaf .infoleafgrp1 {
  top: 3%;
  left: 35%;
  transform: rotate(-125deg);
  width: 5%;
}
.gkt-infoleaf .infoleafgrp4 {
  top: 0%;
  left: 0%;
}
@media only screen and (max-width: 769px) {
  .gkt-teainfo-gridsec {
    padding: 0 2rem;
  }
  .gkt-info-container {
    top: 32%;
  }
  .gkt-teacup-img .teacup-img {
    right: -16%;
    margin-top: 105px;
    height: 383px;
  }
  .gkt-cloud-teainfo-overlay .teainfo-cloudegrpbottom {
    width: 149%;
    bottom: -15%;
    right: -253px;
  }
  .gkt-infoleaf .infoleafblur6 {
    width: 6%;
  }
}
@media only screen and (max-width: 550px) {
  .gkt-teainfo-gridsec {
    padding: 0 1rem;
    position: unset;
  }
  .gkt-teacup-img {
    position: unset;
  }
  .gkt-info-container {
    width: 100%;
    padding-left: 0;
    text-align: center;
    left: 0%;
  }
  .gkt-teacup-img .teacup-img {
    right: -88%;
    height: 240px;
    margin: 0;
  }
  .gkt-cloud-teainfo-overlay .teainfo-cloudegrpbottom {
    bottom: 0%;
    width: 215%;
  }
  .gkt-cloud-teainfo-overlay .teainfo-solocloud {
    width: 87%;
    top: 16%;
    right: 34%;
  }
  .gkt-info-container {
    position: relative;
    left: -80%;
    transform: translateY(0%);
    padding-top: 110px;
  }
  .gkt-infotealeaf-container {
    right: 0;
  }
  .gkt-infoleaf .infoleafgrp4 {
    top: 13%;
    left: 5%;
    height: 57%;
  }
  .gkt-infoleaf .infoleafgrp1 {
    top: 0%;
    left: 46%;
    transform: rotate(-148deg);
    width: 20%;
  }
  .gkt-infoleaf .infoleafblur1 {
    top: 14%;
    left: 36%;
    width: 6%;
  }
  .gkt-infoleaf .infoleafblur6 {
    top: 5%;
    width: 10%;
  }
  .backgroundimage {
    height: 100%;
    object-fit: cover;
    object-position: right;
  }
}
@media screen and (min-height: 900px) and (orientation: portrait) {
  .gkt-info-container {
    padding: 0%;
  }
  .gkt-teainfo-wrapper {
    height: 400px;
  }
  .gkt-teacup-img .teacup-img {
    right: -69%;
    margin-top: 0%;
    height: 375px;
  }
  .gkt-info-container {
    top: 15%;
    left: -30%;
  }
}
