a,
p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.gkt-navbar-wrapper {
  display: grid;
  grid-template-columns: 2rem 4fr 3fr 1fr 2rem;
  height: 11vh;
  z-index: 2;
}
.gkt-navbar-wrapper .gkt-logo-container {
  display: flex;
  align-items: center;
}
.gkt-navbar-wrapper .gkt-logo-container .gkt-logo {
  height: 5rem;
  padding: 10px 0;
  width: auto;
}
.gkt-logo-container {
  grid-column: 2/3;
}

.gkt-nav-container {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
}
.gkt-links-container {
  width: 100%;
  margin: auto 0;
}
.gkt-nav-link {
  text-decoration: none;
  color: #4a9f0f;
  transition: all 0.2s ease;
  padding: 10px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  font-size: 14px;
  outline: none;
}
.gkt-nav-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #a4e378;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.gkt-nav-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom center;
}
.gkt-nav-link.active:after {
  transform: scaleX(1);
  transform-origin: bottom center;
}
.gkt-nav-link:hover {
  color: #a4e378;
}
.gkt-nav-link.active {
  color: #a4e378;
}
.gkt-Gallery-container {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}
.gkt-galary-btn {
  background-color: white;
  border: 2px solid #4a9f0f;
  border-radius: 30px;
  padding: 5px 25px;
  color: #4a9f0f;
  transition: all 0.5s ease;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.gkt-galary-btn:hover {
  background-color: #a4e378;
  border: 2px solid #a4e378;
  color: #4a9f0f;
}
.gkt-hamburder-menu {
  display: none;
}
.gkt-nav-item {
  cursor: pointer;
}
@media only screen and (max-width: 1111px) {
  .gkt-navbar-wrapper {
    grid-template-columns: 2rem 3fr 4fr 1fr 2rem;
  }
}
@media only screen and (max-width: 859px) {
  .gkt-navbar-wrapper {
    grid-template-columns: 2rem 2fr 5fr 1fr 2rem;
  }
}
@media only screen and (max-width: 769px) {
  .gkt-navbar-wrapper {
    grid-template-columns: 2rem 5fr 1fr 2rem;
    height: auto;
    position: relative;
    z-index: 9;
  }
  .gkt-Gallery-container {
    display: none;
  }
  .gkt-hamburder-menu {
    display: flex;
    justify-content: end;
    align-items: center;
    color: #4a9f0f;
    z-index: 9;
  }
  .gkt-logo-container {
    z-index: 9;
  }
  .gkt-links-container {
    grid-row: 2/3;
    grid-column: 1/5;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 8;
    background-color: white;
    transform: translateY(-130%);
    transition: all 0.2s ease;
  }

  .gkt-links-container.gkt-mobile-link {
    transform: translateY(0);
  }
  .gkt-nav-container {
    flex-direction: column;
    padding: 1rem 0;
  }
  .gkt-nav-item {
    padding: 0.5rem 0;
  }
  .gkt-nav-link {
    font-size: 16px;
  }
}
