.gkt-contect-wrapper {
  height: 89vh;
  position: relative;
  overflow: hidden;
}
.gkt-send-wrapper {
  height: auto;
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
}
.gkt-contact-container {
  height: 100%;
}
.mapwithback {
  width: 130vw;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.gkt-contact-bottomwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}

.gkt-contact-cloud-overlay .cloudegrpbottom {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -30%;
  right: 0;
  z-index: 1;
}
.gkt-contact-cloud-overlay .cloudegrpbottom img {
  width: 100%;
}
.gkt-contact-cloud-overlay .cloudegrpbottom1 img {
  width: 100%;
}
.gkt-contact-cloud-overlay .cloudegrpbottom1 {
  width: 100%;
  height: auto;
  position: absolute;
  top: -28%;
  transform: rotate(180deg);
  right: 0;
  z-index: 1;
}
.gkt-contact-cloud-overlay .solocloud {
  width: 50%;
  position: absolute;
  top: -8%;
  right: -10%;
  height: auto;
  z-index: 2;
}
.gkt-contact-cloud-overlay .solocloud1 {
  width: 50%;
  position: absolute;
  top: 25%;
  left: -10%;
  height: auto;
  opacity: 0.7;
  transform: rotate(25deg);
  z-index: 2;
}
.gkt-contact-cloud-overlay .solocloud1 img {
  width: 100%;
}
.gkt-contact-cloud-overlay .solocloud img {
  width: 100%;
}
.gkt-contact-topwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}
.gkt-map-container {
  background-image: url("../images/map.webp");
  background-size: cover;
  height: 100%;
  width: 100%;
  background-position: center 60%;
  background-repeat: no-repeat;
}
.gkt-contact-text .for-horiline {
  display: flex;
  align-items: center;
}
.gkt-horizontal-line {
  border-top: 5px solid white;
  border-radius: 10px;
  width: 150px;
  margin-left: 10px;
  text-shadow: -1px 1px 2px #000;
}
.gkt-contact-text-continer {
  position: relative;
  height: 100%;
  margin: 0 50% 0 15%;
  z-index: 5;
  display: flex;
  align-items: center;
}
.gkt-contact-text {
  color: white;
}
.gkt-contact-h1text {
  font-size: 4rem;
  margin: 0;
  text-shadow: -1px 1px 2px #000;
  line-height: 1;
  font-weight: 700 !important;
}
.gkt-contact-text p {
  text-shadow: -1px 1px 2px #000;
}
.gkt-contact-text a {
  text-shadow: -1px 1px 2px #000;
}

.gkt-address-detailes {
  font-size: 20px;
  margin: 0%;
  color: white;
  text-decoration: none;
}
a.gkt-address-detailes:hover {
  color: #4a9f0f;
}
.gkt-address-detailes i {
  width: 25px;
  text-align: center;
}
.gkt-contact-text h4 {
  margin-top: 2rem;
  width: auto;
  background-color: rgba(255, 255, 255, 1);
  color: #4a9f0f;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  text-transform: uppercase;
}
.conleafgrp21 {
  top: 0%;
  right: 7%;
  width: 145px;
  transform: rotate(174deg);
  height: auto;
}
.conleafgrp3 {
  top: -10%;
  left: 10%;
  width: 30%;
  height: auto;
}
.conleafblur6 {
  top: 7%;
  left: 6%;
  transform: rotate(167deg);
  width: 70px;
  height: auto;
}
.colleafblur1 {
  bottom: 3%;
  right: 11%;
  transform: rotate(85deg);
  width: 75px;
  height: auto;
}
.colleafblur11 {
  bottom: 8%;
  right: 6%;
  transform: rotate(185deg);
  width: 50px;
  height: auto;
}
.gkt-sendcontact-box {
  height: 100%;
}
.gkt-sandcontactgrid-box {
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  position: relative;
  height: 100%;
  align-items: center;
}
.gkt-sandcontactgrid-box .gkt-side-navigation {
  grid-column: 2/3;
  margin-top: 3vh;
}
.gkt-sandcontactgrid-box .gkt-side-navigation p {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
  color: #576f4f;
}
.gkt-sandcontactgrid-box .gkt-varticle-line {
  margin-left: 3px;
  margin-bottom: 0.6rem;
  border-left: 1px solid #4a9f0f;
  height: 60px;
}
.gkt-main-sendcontact-sec {
  position: relative;
  grid-column: 2/3;
}
.gkt-main-sendcontact-sec .gkt-big-hedding {
  position: absolute;
  width: 100%;
}
.gkt-main-sendcontact-sec .gkt-big-hedding {
  position: absolute;
  top: -15%;
  right: 0;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
}
.gkt-main-sendcontact-sec .gkt-big-hedding h1 {
  font-size: 15vw;
  color: #a4e373;
  margin-bottom: 5rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.gkt-main-sendcontact-heding {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
  width: 100%;
}
.gkt-main-sendcontact-heding h1 {
  font-family: "Times New Roman", Times, serif;
  color: #576f4f;
  position: relative;
  z-index: 2;
}
.gkt-sendsetailes {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0 3rem;
}
.gkt-main-sendcontact-send {
  width: 65%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}
.gkt-main-sendcontact-detailes {
  width: 35%;
  padding-top: 2rem;
  color: #576f4f;
}
.inputtag-sendcontect {
  padding: 1rem 2rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #4a9f0f;
  margin: 1rem 0;
  color: #576f4f;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.1);
}
.inputtag-sendcontect:focus {
  outline: 2px solid #4b9f0f2d;
  background-color: rgba(255, 255, 255, 0.4);
}
.gkt-end-inner {
  display: flex;
}
.gkt-end-inner input {
  margin: 1rem 10px;
}
.gkt-send-innner-inner {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #576f4f;
}
.gkt-send-innner-inner label {
  padding: 0 0 0 10px;
}
.gkt-sendcontact-btn {
  border: 2px solid #4a9f0f;
  background-color: #4a9f0f;
  text-transform: capitalize;
  color: #a4e373;
  border-radius: 5px;
  margin-left: auto;
  transition: all 0.3s ease;
  padding: 0.5rem 2rem;
}
.gkt-sendcontact-btn:hover {
  background-color: #a4e373;
  color: #4a9f0f;
}
.gkt-main-sendcontact-detailes p {
  padding-left: 2rem;
  margin-bottom: 2rem;
}
.gkt-main-sendcontact-detailes h4 {
  background-color: #4a9f0f;
  color: #a4e373;
  width: 220px;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 18px;
}
.gkt-tealeaf-container-sand {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
}
.gkt-leaf-sand {
  height: 100%;
  position: relative;
}
.gkt-leaf-sand .consendleafblur6 {
  top: 22%;
  left: 5%;
  transform: rotate(85deg);
  width: 75px;
}
.gkt-leaf-sand .colsandleafblur1 {
  bottom: 14%;
  right: 11%;
  transform: rotate(183deg);
  width: 40px;
}
.gkt-leaf-sand .colsandleafblur11 {
  top: 15%;
  left: -10%;
  transform: rotate(270deg);
  width: 198px;
}
.gkt-leaf-sand .colsandleafblur3 {
  top: 24%;
  left: 7%;
  transform: rotate(270deg);
  width: 86px;
}
.gkt-leaf-sand .colsandleafblur4 {
  top: 15%;
  right: 1%;
  transform: rotate(321deg);
  width: 82px;
}
.gkt-leaf-sand .consandleafgrp3 {
  top: -35%;
  left: 25%;
  width: 40%;
  transform: rotate(295deg);
}
.gkt-leaf-sand .colsandleafgrp31 {
  bottom: -35%;
  right: -5%;
  width: 20%;
  transform: rotate(272deg);
}
.gkt-leaf-sand .colsandleafgrp2 {
  bottom: -17%;
  left: -4%;
  width: 28%;
  transform: rotate(258deg);
}
.gkt-leaf-sand .colsandleafblur31 {
  bottom: 70%;
  right: 0%;
  width: 6%;
  transform: rotate(121deg);
}
.gkt-leaf-sand .colsandleafblur2 {
  top: 35%;
  right: 27%;
  width: 6%;
  transform: rotate(151deg);
}
.aboutuslead img {
  width: 100%;
}
.suceesmsg {
  width: 100%;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 0.25rem;
  padding: 0.6rem 1.25rem;
  margin: 0%;
  display: none;
}
.unsuccessmsg {
  width: 100%;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  padding: 0.6rem 1.25rem;
  margin: 0%;
  display: none;
}
.sendbutton {
  width: 100%;
}
.loddingofbutton {
  width: 100%;
  display: none;
  animation: mymove 2s infinite;
}
@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 1111px) {
  .gkt-map-container {
    background-position: 80% 60%;
  }
  .gkt-sandcontactgrid-box {
    grid-template-columns: 0rem 1fr 0rem;
  }
}
@media only screen and (max-width: 880px) {
  .gkt-sendsetailes {
    flex-direction: column;
  }
  .gkt-send-wrapper {
    height: auto;
  }
  .gkt-main-sendcontact-detailes {
    width: 100%;
  }
  .gkt-main-sendcontact-send {
    width: 100%;
  }
  .gkt-main-sendcontact-detailes {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  .gkt-main-sendcontact-detailes p {
    padding: 0%;
  }
}

@media only screen and (max-width: 650px) {
  .gkt-map-container {
    background-position: 100% 50%;
  }
  .gkt-contact-text {
    text-align: center;
  }
  .gkt-contact-text-continer {
    margin: 0 15%;
  }
  .gkt-contact-text h4 {
    margin-top: 12rem;
    width: 100%;
  }
  .for-horiline {
    justify-content: center;
  }
  .gkt-address-detailes {
    font-size: 16px;
  }
  .gkt-main-sendcontact-detailes {
    flex-direction: column;
  }
  .gkt-main-sendcontact-detailes h4 {
    margin-left: auto;
    margin-right: auto;
  }
  .gkt-sendsetailes {
    padding: 0%;
  }
  .gkt-end-inner {
    flex-direction: column;
  }
  .gkt-send-innner-inner {
    width: 100%;
  }
  .colleafblur1 {
    right: 24%;
  }
  .gkt-leaf-sand .colsandleafblur11 {
    width: 150px;
  }
  .gkt-leaf-sand .colsandleafblur3 {
    width: 70px;
  }
  .gkt-leaf-sand .colsandleafblur4 {
    width: 62px;
  }
}

@media only screen and (max-width: 450px) {
  .gkt-map-container {
    background-position: 88% 50%;
  }
}
