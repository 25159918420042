.gkt-brochure-wrapper {
  height: 89vh;
  overflow: hidden;
}
.gkt-brochur-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.backgroundimagebox {
  position: absolute;
  top: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  background: url("../images/assamteaback.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  opacity: 0.1;
}
.demoPage {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.demoPage img {
  width: 100%;
  height: 100%;
}
.stf__parent {
  margin: 0 auto;
}
.navibuttons {
  padding: 1rem 0;
  position: relative;
  z-index: 1;
}
.navibuttons button {
  width: 200px;
  border: 2px solid #4a9f0f;
  background-color: #4a9f0f;
  text-transform: capitalize;
  color: #a4e373;
  border-radius: 5px;
  padding: 0.5rem 2rem;
  margin: 0 0.5rem;
  transition: all 0.3s ease;
}
.navibuttons button:hover {
  background-color: #a4e373;
  color: #4a9f0f;
}
