.gkt-Benefits-wrapper {
  height: 100vh;
  position: relative;
  padding: 3rem 0;
}
.gkt-Benefits-box {
  height: 100%;
  overflow: hidden;
}
.gkt-Benefitsgrid-box {
  display: grid;
  grid-template-columns: 3rem 1fr 10fr 1fr 3rem;
  position: relative;
  height: 100%;
}
.gkt-Benefitsgrid-box .gkt-side-navigation {
  grid-column: 2/3;
  margin-top: 3vh;
  z-index: 3;
  display: block;
  transition: all 0.5s ease;
}
.gkt-Benefitsgrid-box .gkt-side-navigation p {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
  color: #576f4f;
}
.gkt-Benefitsgrid-box .gkt-side-navigation .side-link {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
  color: #576f4f;
  text-decoration: none;
}
.gkt-Benefitsgrid-box .gkt-varticle-line {
  margin-left: 3px;
  margin-bottom: 0.6rem;
  border-left: 1px solid #4a9f0f;
  height: 60px;
}
.gkt-main-Benefits-heding {
  margin-top: 8vh;
  text-align: center;
}
.gkt-main-Benefits-heding h1 {
  color: #576f4f;
  text-align: center;
  margin-bottom: 2rem;
}
.gkt-main-Benefits-heding p {
  color: #576f4f;
  text-align: center;
  text-transform: none;
  font-size: 18px;
}

.gkt-main-Benefits-sec {
  padding: 3rem 4rem;
  position: relative;
}

.gkt-main-Benefits-sec .gkt-tealeaf-img {
  position: absolute;
  bottom: 0%;
  left: -5%;
  height: 12vw;
  z-index: 0;
}
.gkt-main-Benefits .gkt-teacup-img {
  position: absolute;
  top: -10%;
  right: -12%;
  height: 24vw;
  transform: rotate(-45deg);
}
.gkt-main-Benefits {
  text-align: center;
  position: relative;
  padding: 1rem;
  height: 100%;
  color: #576f4f;
  background-color: rgba(165, 227, 120, 0.63);
  display: flex !important;
  align-items: center;
  overflow: hidden;
}
.gkt-benifits {
  padding: 0 1rem;
}
.gkt-benifits-inner {
  margin: auto 0;
  height: 35vh;
  padding: 0 1rem;
  border: 1px solid rgba(75, 159, 15, 0.337);
  box-shadow: 0 0.125rem 0.25rem rgba(86, 110, 78, 0.1);
  transition: 0.35s all ease;
}
.gkt-benifits-inner:hover {
  background-color: #a4e378;
}
.gkt-benifits i {
  margin: 1rem 0;
  color: #4a9f0f;
}
.gkt-benifits h4 {
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: bolder;
  color: #576f4f;
}
.gkt-benifits p {
  font-size: 14px;
  color: #4a9f0f;
}
.gkt-Benefits-colorbox {
  width: 100%;
}
.gkt-benefits-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 1;
  position: relative;
}
.gkt-banefits-detailes {
  padding-bottom: 2rem;
  position: relative;
}
.gkt-banefits-detailes h3 {
  font-size: 5vw;
  margin: 0;
  color: #4a9f0f;
  letter-spacing: 1px;
  margin-bottom: 2vh;
  font-family: "Times New Roman", Times, serif;
}
.gkt-banefits-detailes p {
  position: absolute;
  left: 0;
  right: 0;
}
.gkt-bnitealeaf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.gkt-bnileaf {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.gkt-bnileaf .bnileafblur6 {
  top: 12%;
  right: 5%;
  transform: rotate(150deg);
  width: 3%;
}
.gkt-bnileaf .bnileafblur1 {
  top: 30%;
  right: 1%;
  transform: rotate(100deg);
  width: 1%;
}
.gkt-bnileaf .bnileafgrp5 {
  top: 5%;
  right: 30%;
  transform: rotate(80deg);
  width: 5%;
}
.gkt-bnileaf .bnileafgrp3 {
  top: 10%;
  right: 20%;
  transform: rotate(80deg);
  width: 7%;
}
.gkt-bnileaf .bnileafgrp31 {
  top: 30%;
  right: 8%;
  transform: rotate(50deg);
  width: 10%;
}
.gkt-bnileaf .bnileafgrp2 {
  top: 30%;
  right: 5%;
  transform: rotate(50deg);
  width: 10%;
}
.gkt-bnileaf .bnileafgrp21 {
  top: 5%;
  right: 10%;
  transform: rotate(100deg);
  width: 3%;
}
.gkt-bnileaf .bnileafblur61 {
  top: 10%;
  right: 2%;
  transform: rotate(50deg);
  width: 2%;
}
.gkt-bnileaf .bnileafblur11 {
  top: 70%;
  right: 4%;
  transform: rotate(0deg);
  width: 2%;
}
.gkt-bnileaf .bnileafblur3 {
  top: 0%;
  left: 5%;
  transform: rotate(0deg);
  width: 2%;
}
@media only screen and (max-width: 769px) {
  .gkt-Benefits-wrapper {
    height: auto;
  }
  .gkt-Benefitsgrid-box .gkt-side-navigation {
    display: none;
  }
  .gkt-main-Benefits-sec {
    grid-column: 2/5;
    padding: 0;
  }
  .gkt-main-Benefits {
    padding: 0;
  }
  .gkt-benefits-grid {
    display: block;
  }
  .gkt-benifits {
    padding: 0 1rem 1rem;
  }
  .gkt-benifits-inner {
    height: auto;
  }
  .gkt-banefits-detailes {
    padding: 2rem 0 1rem 0;
  }
  .gkt-banefits-detailes h3 {
    font-size: 17vw;
    margin-bottom: 10vh;
  }
  .gkt-main-Benefits-sec .gkt-tealeaf-img {
    bottom: -8%;
    height: 21vw;
  }
}
@media only screen and (max-width: 550px) {
  .gkt-Benefitsgrid-box {
    grid-template-columns: 0rem 1fr 10fr 1fr 0rem;
  }

  .gkt-main-Benefits-sec {
    grid-column: 2/5;
  }

  .gkt-main-Benefits-sec .gkt-tealeaf-img {
    height: 34vw;
    bottom: -4%;
    left: -23%;
  }
  .gkt-banefits-detailes p {
    padding: 0 2rem;
  }
}
@media screen and (min-height: 900px) and (orientation: portrait) {
  .gkt-benefits-grid {
    display: flex;
    flex-direction: column;
  }
  .gkt-benifits-inner {
    height: auto;
  }
  .gkt-main-Benefits-sec {
    padding: 2rem 2rem;
  }
}
