.gkt-about-wrapper {
  position: relative;
  padding: 3rem 0;
  overflow-x: hidden;
}
.gkt-about-box {
  height: 100%;
  position: relative;
  z-index: 2;
}
.gkt-aboutgrid-box {
  display: grid;
  grid-template-columns: 3rem 1fr 10fr 1fr 3rem;
  position: relative;
  height: 100%;
}
.gkt-aboutgrid-box .gkt-side-navigation {
  grid-column: 2/3;
  margin-top: 3vh;
  z-index: 3;
}
.gkt-aboutgrid-box .gkt-side-navigation p {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
  color: #576f4f;
  transition: all 0.5s ease;
}
.gkt-aboutgrid-box .gkt-side-navigation .side-link {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
  color: #576f4f;
  text-decoration: none;
}
.gkt-aboutgrid-box .gkt-varticle-line {
  margin-left: 3px;
  margin-bottom: 0.6rem;
  border-left: 1px solid #4a9f0f;
  height: 60px;
}
.gkt-main-about-heding {
  margin-top: 8vh;
  text-align: center;
}
.gkt-main-about-heding h1 {
  color: #576f4f;
  text-align: center;
  margin-bottom: 2rem;
}
.gkt-main-about-heding p {
  color: #576f4f;
  text-align: center;
  text-transform: none;
  font-size: 18px;
  margin-bottom: 2rem;
  width: 100%;
}
.gkt-main-about-heding .firstp {
  margin-left: 8vw;
}
.gkt-main-about-heding .secondp {
  position: relative;
  right: 7vw;
}
.gkt-about-hedding-box {
  height: 25vh;
  position: relative;
  margin-bottom: 5vh;
}
.gkt-about-hedding-box .gkt-about-round {
  position: absolute;
  right: 50%;
  top: 0;
  transform: translateX(50%);
  width: 25vh;
  height: 25vh;
  background-color: rgba(165, 227, 120, 0.63);
  border-radius: 50%;
  z-index: 0;
}
.gkt-about-hedding-box h1 {
  position: relative;
  z-index: 1;
  font-size: 15vh;
  color: #4a9f0f;
  text-transform: uppercase;
  margin: 0;
  font-weight: 700 !important;
}
.gkt-about-hedding-box .tea-leaf-forabout img {
  height: 70%;
  position: absolute;
  bottom: -5%;
  right: 50%;
  z-index: 2;
  transform: translateX(50%);
}
.auout_usbtn {
  text-transform: uppercase;
  padding: 10px 20px;
  background-color: #4a9f0f;
  border: 1px solid #576f4f;
  color: #a4e378;
  text-decoration: none;
  transition: 0.2s all ease;
  border-radius: 3px;
  margin-top: 2vh;
}
.auout_usbtn:hover {
  background-color: #a4e378;
  color: #576f4f;
}
.gkt-abtealeaf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.gkt-ableaf {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.gkt-ableaf .ableafblur6 {
  top: 2%;
  right: 3%;
  transform: rotate(-98deg);
  width: 3%;
}
.gkt-ableaf .ableafblur1 {
  top: 3%;
  right: 12%;
  transform: rotate(-84deg);
  width: 1%;
}
.gkt-ableaf .ableafgrp5 {
  top: -10%;
  right: 8%;
  transform: rotate(84deg);
  width: 8%;
}
.gkt-ableaf .ableafgrp3 {
  top: -15%;
  right: 21%;
  transform: rotate(0deg);
  width: 16%;
}
.gkt-ableaf .ableafgrp4 {
  top: -17%;
  right: -11%;
  transform: rotate(-1deg);
  width: 18%;
}
.gkt-ableaf .ableafblur61 {
  top: 43%;
  right: 5%;
  transform: rotate(20deg);
  width: 3%;
}
.gkt-ableaf .ableafblur11 {
  bottom: 26%;
  right: 2%;
  transform: rotate(-151deg);
  width: 1%;
}
.gkt-ableaf .ableafgrp51 {
  bottom: 30%;
  right: 2%;
  transform: rotate(99deg);
  width: 6%;
}
.gkt-ableaf .ableafgrp31 {
  bottom: 16%;
  right: 18%;
  transform: rotate(0deg);
  width: 11%;
}
.gkt-ableaf .ableafgrp41 {
  bottom: -12%;
  right: 24%;
  transform: rotate(-18deg);
  width: 14%;
}
.gkt-ableaf .ableafgrp52 {
  bottom: 30%;
  right: 38%;
  transform: rotate(8deg);
  width: 5%;
}
.gkt-ableaf .ableafgrp32 {
  bottom: 13%;
  right: 20%;
  transform: rotate(-74deg);
  width: 27%;
}
.gkt-ableaf .ableafgrp33 {
  bottom: 30%;
  right: 23%;
  transform: rotate(-166deg);
  width: 10%;
}
.gkt-ableaf .ableafgrp42 {
  top: -34%;
  left: 2%;
  transform: rotate(11deg);
  width: 12%;
}
.gkt-ableaf .ableafblur12 {
  top: 5%;
  left: 37%;
  transform: rotate(8deg);
  width: 1%;
}
.gkt-ableaf .ableafgrp1 {
  top: 26%;
  left: 5%;
  transform: rotate(11deg);
  width: 17%;
}
.gkt-ableaf .abfooter2 {
  bottom: 60%;
  left: -7%;
  transform: rotate(72deg);
  width: 15%;
}
.gkt-ableaf .ableafgrp2 {
  bottom: 30%;
  left: 1%;
  transform: rotate(119deg);
  width: 6%;
}
.gkt-ableaf .ableafgrp11 {
  bottom: 34%;

  left: 9%;
  transform: rotate(102deg);
  width: 6%;
}
@media only screen and (max-width: 769px) {
  .gkt-about-wrapper {
    height: auto;
  }
  .gkt-aboutgrid-box {
    grid-template-columns: 1.5rem 1fr 10fr 1fr 1.5rem;
  }
  .gkt-main-about-sec {
    grid-column: 3/4;
  }
  .gkt-about-hedding-box h1 {
    font-size: 15vw;
  }
}
@media only screen and (max-width: 550px) {
  .gkt-aboutgrid-box .gkt-side-navigation {
    display: none;
  }
  .gkt-main-about-sec {
    grid-column: 2/5;
  }
  .gkt-about-hedding-box h1 {
    font-size: 15vw;
  }
  .gkt-about-hedding-box .gkt-about-round {
    width: 15vh;
    height: 15vh;
  }
  .gkt-about-hedding-box .tea-leaf-forabout img {
    height: 66%;
    bottom: 0%;
    right: 52%;
  }
  .gkt-about-hedding-box {
    height: 15vh;
  }
  .gkt-main-about-heding {
    margin-top: 4vh;
  }
  .gkt-ableaf .ableafblur6 {
    width: 7%;
  }
  .gkt-ableaf .ableafblur1 {
    top: 2%;
    right: 22%;
    width: 3%;
  }
  .gkt-ableaf .ableafgrp4 {
    top: 11%;
    width: 26%;
  }
  .gkt-ableaf .ableafblur61 {
    right: -8%;
  }
  .gkt-ableaf .ableafblur11 {
    width: 6%;
  }
  .gkt-ableaf .ableafgrp51 {
    width: 20%;
  }
  .gkt-ableaf .ableafgrp31 {
    bottom: 4%;
    right: 7%;
    width: 22%;
  }
  .gkt-ableaf .ableafgrp41 {
    bottom: -3%;
    right: 18%;
    width: 25%;
  }
  .gkt-ableaf .ableafgrp52 {
    bottom: 9%;
    width: 13%;
  }
  .gkt-ableaf .ableafgrp42 {
    top: -1%;
    width: 16%;
  }
  .gkt-ableaf .ableafblur12 {
    width: 4%;
  }
  .gkt-ableaf .abfooter2 {
    left: -19%;
    width: 40%;
  }
  .gkt-ableaf .ableafgrp2 {
    width: 15%;
  }
  .gkt-ableaf .ableafgrp11 {
    bottom: 4%;
    left: 8%;
    width: 24%;
  }
}
